// _____________________________________________________________________________
// General imports
//

import Vue from 'vue';


//_____________________________________________________________________________
// Import Vue components

import TilesContainerVue from './vue/tiles-container.vue';


//_____________________________________________________________________________
// Start Vue

if (document.getElementById('tiles-container-vue')) {
    new Vue({
        el: '#tiles-container-vue',
        render: function (createElement) {
            return createElement(TilesContainerVue);
        },
    });
}
