import lodash from 'lodash';
import uuidv4 from 'uuid/v4';

class Tile {

    //_____________________________________________________________________________
    // Constructor

    /**
     * @param {string} type
     * @param {array<string>} categories
     * @param {object} content
     * @param {array<array<string>>} sticksWithCategoriesCombinations
     * @param {string} url
     * @param {array<string>} classes
     * @param {string} id
     * @returns {Tile}
     */
    constructor(type, categories, content, sticksWithCategoriesCombinations = [], url = undefined, classes = [], id = undefined) {
        this._type = type;
        this._categories = categories;
        this._sticksWithCategoriesCombinations = sticksWithCategoriesCombinations;
        this._content = content;
        this._url = url;
        this._classes = classes;

        if (id) {
            this._id = id;
        } else {
            this._id = uuidv4();
        }
    }

    //_____________________________________________________________________________
    // Main properties

    /**
     * @returns {string}
     */
    get type() {
        return this._type;
    }

    /**
     * @returns {array<string>}
     */
    get categories() {
        return this._categories;
    }

    /**
     * @returns {object}
     */
    get content() {
        return this._content;
    }

    /**
     * @returns {string}
     */
    get url() {
        return this._url;
    }

    /**
     * @returns {array<string>}
     */
    get classes() {
        return this._classes;
    }

    /**
     * @returns {string}
     */
    get id() {
        return this._id;
    }


    // _____________________________________________________________________________
    // Further properties

    /**
     * @returns {boolean}
     */
    get hasUrl() {
        return (this.url ? true : false);
    }

    /**
     * @returns {boolean}
     */
    get isProductTile() {
        return this.type === 'product';
    }

    /**
     * @returns {boolean}
     */
    get isImageWithQuoteTile() {
        return this.type === 'image-with-quote';
    }

    /**
     * @returns {boolean}
     */
    get isTextOnlyTile() {
        return this.type === 'text-only';
    }

    /**
     * @returns {boolean}
     */
    get isImageOnlyTile() {
        return this.type === 'image-only';
    }

    /**
     * @returns {boolean}
     */
    get isNeverFilteredAway() {
        // At the moment, quotes are not filtered away
        return this.isImageWithQuoteTile;
    }


    // _____________________________________________________________________________
    // Product properties

    /**
     * @returns {string}
     */
    get productImageUrl() {
        return this.content.productImageUrl;
    }

    /**
     * @returns {string}
     */
    get productImageAltAttribute() {
        let productTypeAndTitle = this.productTypeAndTitle;
        if (productTypeAndTitle) {
            return `Ivana Steiner ${this.productTypeAndTitle}`;
        } else {
            return 'Ivana Steiner';
        }
    }

    /**
     * @returns {string}
     */
    get productImageTitleAttribute() {
        return this.productTypeAndTitle;
    }

    /**
     * @returns {string}
     */
    get productTypeAndTitle() {
        let productTypeAndTitleArray = [];
        if (this.productType) {
            productTypeAndTitleArray.push(this.productType);
        }
        if (this.productTitle) {
            productTypeAndTitleArray.push(this.productTitle);
        }

        if (productTypeAndTitleArray.length === 0) {
            return undefined;
        } else {
            return productTypeAndTitleArray.join(' ');
        }
    }

    /**
     * @returns {string}
     */
    get productTitle() {
        return this.content.productTitle;
    }

    /**
     * @returns {string}
     */
    get productType() {
        return this.content.productType;
    }

    /**
     * @returns {string}
     */
    get productPrice() {
        return this.content.productPrice;
    }


    // _____________________________________________________________________________
    // Image with quote tile

    /**
     * @returns {string}
     */
    get imageWithQuoteImageUrl() {
        return this.content.imageUrl;
    }

    /**
     * @returns {string}
     */
    get imageWithQuoteImageAltAttribute() {
        return this.content.imageAltAttribute;
    }

    /**
     * @returns {string}
     */
    get imageWithQuoteQuoteHeadline() {
        return this.content.quoteHeadline;
    }

    /**
     * @returns {string}
     */
    get imageWithQuoteQuoteBody() {
        return this.content.quoteBody;
    }


    //___________________________________________________________________
    // Text only tile

    /**
     * @returns {string}
     */
    get textOnlyHeadline() {
        return this.content.headline;
    }

    /**
     * @returns {string}
     */
    get textOnlyBody() {
        return this.content.body;
    }


    //___________________________________________________________________
    // Image only tile

    /**
     * @returns {string}
     */
    get imageOnlyImageUrl() {
        return this.content.imageUrl;
    }

    /**
     * @returns {string}
     */
    get imageOnlyImageAltAttribute() {
        return this.content.imageAltAttribute;
    }


    // _____________________________________________________________________________
    // Methods

    /**
     * Checks if the tile has ALL the given categories.
     * @returns {boolean}
     */
    hasAllCategories(categories) {
        return categories.every((category) => {
            return this.hasCategory(category);
        });
    }

    /**
     * @returns {boolean}
     */
    hasCategory(category) {
        return lodash.includes(this.categories, category);
    }

    /**
     * Checks if this tile shall stick with the given categories (they all must be met in combination).
     * @returns {boolean}
     */
    sticksWithAllCategories(categories) {
        return this._sticksWithCategoriesCombinations.some((categoriesCombination) => {
            return categories.every(category => categoriesCombination.includes(category));
        });
    }
}


// _____________________________________________________________________________
// Static methods

/**
 * @param {object} serverStructure
 * @returns {Tile}
 */
Tile.makeFromServerStructure = function (serverStructure) {
    let tile = new Tile(
        serverStructure.type, serverStructure.categories,
        serverStructure.content, serverStructure.sticksWithCategoriesCombinations,
        serverStructure.url, serverStructure.classes,
        serverStructure.id
    );

    return tile;
};


//_____________________________________________________________________________
// Export

export default Tile;
