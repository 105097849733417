<template>
    <div ref="tiles-container-ref"
         class="tiles-container">
        <!-- We need an element to tell the masonry-grid the size of the items. This element needs to be dimensioned via CSS: -->
        <div class="masonry-grid-sizer">
        </div>

        <transition-group class="tile-transition"
                          tag="div"
                          v-on:before-enter="beforeEnter"
                          v-on:enter="enter"
                          v-on:before-leave="beforeLeave"
                          v-on:leave="leave">
            <tile class="consider-for-masonry"
                  v-for="tile of tilesToShow"
                  :tile="tile"
                  :show-product-description="showProductDescriptionInTiles"
                  :key="tile.id">
            </tile>
        </transition-group>
    </div>
</template>

<script>
import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';
import Velocity from 'velocity-animate';
import tilesContainer from '../model/TilesContainer';
import TileVue from './tile.vue';

// _____________________________________________________________________________
// Set Some configuration options that come from the server
//
// When these values are undefined, we set them to a reasonable default value:
const enableFilteringOfTiles =
    window.enableFilteringOfTiles === undefined
        ? true
        : window.enableFilteringOfTiles;
const showProductDescriptionInTiles =
    window.showProductDescriptionInTiles === undefined
        ? true
        : window.showProductDescriptionInTiles;

const fadingAnimationDuration = 500;

export default {
    components: {
        tile: TileVue,
    },
    data() {
        return {
            tilesContainer,
            showProductDescriptionInTiles,
            staticAssetsBasePath: window.staticAssetsBasePath,
        };
    },
    mounted() {
        this.applyMasonryLayout();
    },
    computed: {
        getTilesContainerDomElement() {
            return this.$refs['tiles-container-ref'];
        },
        tilesToShow() {
            if (enableFilteringOfTiles) {
                return this.tilesContainer.filteredTiles;
            } else {
                return this.tilesContainer.tiles;
            }
        },
    },
    watch: {
        tilesToShow: {
            handler() {
                this.applyMasonryLayout();
            },
        },
    },
    methods: {
        applyMasonryLayout() {
            // NOTE: We use "nextTick" here, so we can make sure the DOM has alredy been rendered correctly.
            this.$nextTick(() => {
                imagesLoaded(this.getTilesContainerDomElement, () => {
                    new Masonry('.tiles-container', {
                        itemSelector: '.tile.consider-for-masonry',
                        columnWidth: '.masonry-grid-sizer',
                        percentPosition: true,
                        // Because we define our own CSS transition, we disable the library's own transitions:
                        transitionDuration: 0,
                    });
                });
            });
        },
        // _____________________________________________________________________________
        // Animation events
        //_____________________________________________________________________________
        // TRANSITION FUNCTIONS WITH ANIMATIONS
        //
        //_____________________________________________________________________________
        // Entering
        beforeEnter: function(el) {
            // When entering, an element needs to start with zero opacity (which is then animated till 1)
            el.style.opacity = 0;
        },
        enter: function(el, done) {
            Velocity(
                el,
                {
                    opacity: 1,
                },
                {
                    duration: fadingAnimationDuration,
                    complete: done,
                }
            );
        },
        //_____________________________________________________________________________
        // Leaving
        beforeLeave: function(el) {
            // When the element leaves, we do not consider it for the masonry layout anymore:
            el.classList.remove('consider-for-masonry');
            el.classList.add('ignore-for-masonry');
        },
        leave: function(el, done) {
            Velocity(
                el,
                {
                    opacity: 0,
                },
                {
                    duration: fadingAnimationDuration,
                    complete: done,
                }
            );
        },
    },
};
</script>
