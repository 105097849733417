<template>
    <!-- We use a built-in "component" element to dynamically set the tag-type
    Also see: https://stackoverflow.com/a/44396817 -->
    <component class="tile"
               :is="containerTagType"
               :href="tile.url"
               :class="tile.classes">

        <template v-if="tile.isProductTile">
            <div class="product-tile">
                <div class="product-tile__image">
                    <img :src="tile.productImageUrl"
                         :alt="tile.productImageAltAttribute"
                         :title="tile.productImageTitleAttribute"/>
                </div>
                <div class="product-tile__description"
                     v-if="showProductDescription">
                    <span class="product-tile__type">{{tile.productType}}</span>
                    <span class="product-tile__title">{{tile.productTitle}}</span>
                </div>
                <div class="product-tile__price"
                     v-if="tile.productPrice">
                    <span class="product-tile__price-number">{{tile.productPrice}}</span> EUR ex. VAT
                </div>
            </div>
        </template>
        <template v-else-if="tile.isImageWithQuoteTile">
            <div class="image-with-quote-tile">
                <div class="image-with-quote-tile__image">
                    <img :src="tile.imageWithQuoteImageUrl"
                         :alt="tile.imageWithQuoteImageAltAttribute"
                         :title="tile.imageWithQuoteImageAltAttribute"/>
                </div>
                <div class="image-with-quote-tile__quote">
                    <div class="image-with-quote-tile__quote-headline">
                        {{ tile.imageWithQuoteQuoteHeadline }}
                        <div class="image-with-quote-tile__quote-symbol">
                            &rdquo;
                        </div>
                    </div>
                    <div class="image-with-quote-tile__quote-body">
                        {{ tile.imageWithQuoteQuoteBody }}
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="tile.isTextOnlyTile">
            <div class="text-only-tile">
                <div v-if="tile.textOnlyHeadline"
                     class="text-only-tile__headline"
                     v-html="tile.textOnlyHeadline">
                </div>
                <div v-if="tile.textOnlyBody"
                     class="text-only-tile__body"
                     v-html="tile.textOnlyBody">
                </div>
            </div>
        </template>
        <template v-else-if="tile.isImageOnlyTile">
            <div class="image-only-tile">
                <div class="image-with-quote-tile__image">
                    <img :src="tile.imageOnlyImageUrl"
                         :alt="tile.imageOnlyImageAltAttribute"
                         :title="tile.imageOnlyImageAltAttribute"/>
                </div>
            </div>
        </template>

    </component>
</template>

<script>
    import Tile from '../model/Tile';

    export default {
        props: {
            tile: {
                type: Tile,
                required: true,
            },
            showProductDescription: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                staticAssetsBasePath: window.staticAssetsBasePath,
            };
        },
        computed: {
            containerTagType() {
                if (this.tile.hasUrl) {
                    return 'a';
                } else {
                    return 'div';
                }
            },
        },
    };
</script>
