import lodash from 'lodash';
import Tile from './Tile';

/**
 * THIS IS A SINGLETON!
 * We only expect one tiles-container can exist per page.
 */
class TilesContainer {

    //_____________________________________________________________________________
    // Constructor

    /**
     * @param {array<Tile>} tiles
     * @returns {TilesContainer}
     */
    constructor(tiles) {
        this._tiles = tiles;
        this._filteredTiles = this._tiles;
    }

    //_____________________________________________________________________________
    // Properties

    /**
     * @returns {array<Tile>}
     */
    get tiles() {
        return this._tiles;
    }

    /**
     * @returns {array<Tile>}
     */
    get filteredTiles() {
        return this._filteredTiles;
    }

    // _____________________________________________________________________________
    // Methods

    filterAccordingToCurrentHashPath() {
        const hashPath = window.location.hash;
        const hashPathParts = lodash.without(lodash.compact(hashPath.split('/')), '#');
        const activeCategories = hashPathParts;

        // The hash path contains all categories of the tile that shall be visible:
        this._filteredTiles = lodash.filter(this._tiles, (tile) => {
            return (tile.isNeverFilteredAway || tile.sticksWithAllCategories(activeCategories) || tile.hasAllCategories(activeCategories));
        });
    }
}


// _____________________________________________________________________________
// Static methods

/**
 * @param {object} serverStructure
 * @returns {TilesContainer}
 */
TilesContainer.makeFromServerStructure = function (serverStructure) {
    let tileObjects = [];

    for (const tileFromServer of serverStructure) {
        let tile = Tile.makeFromServerStructure(tileFromServer);
        tileObjects.push(tile);
    }

    let tilesContainer = new TilesContainer(tileObjects);
    return tilesContainer;
};


//_____________________________________________________________________________
// Create singleton

// We expect a global variable "tiles" here:
const tilesFromServer = (window.tiles === undefined ? [] : window.tiles);
const tilesContainer = TilesContainer.makeFromServerStructure(tilesFromServer);


// _____________________________________________________________________________
// Attach event handlers to singleton

window.onhashchange = function () {
    tilesContainer.filterAccordingToCurrentHashPath();
};
// Also initialize the filter of the container once:
tilesContainer.filterAccordingToCurrentHashPath();


// _____________________________________________________________________________
// Export singleton

export default tilesContainer;
