// _____________________________________________________________________________
// Imports

import $ from 'jquery';
import Velocity from 'velocity-animate';


// _____________________________________________________________________________
// Variables

let menuAnimationIsRunning = false;
let isMenuExpanded = false;


// _____________________________________________________________________________
// Event attachments

$(document).ready(() => {
    $('.top-header__hamburger-or-close-icon').click(function () {
        onHamburgerOrCloseIconClicked();
    });

    $('.top-header__navigation-level-1-item-link, .top-header__navigation-level-2-item-link, .top-header__navigation-level-3-item-link').click(function () {
        let $clickedItemLink = $(this);
        onItemLinkClicked($clickedItemLink);
    });
});


// _____________________________________________________________________________
// Event handlers

function onHamburgerOrCloseIconClicked() {
    if (!menuAnimationIsRunning) {
        toggleNavigationMenu();
    }
}

function onItemLinkClicked($clickedItemLink) {
    const $clickedItem = getParentItemFromItemLink($clickedItemLink);
    setActiveItem($clickedItem);
    const $nextLevelContainer = getNextLevelContainerFromItemLink($clickedItemLink);
    toggleContainer($nextLevelContainer);
}


// _____________________________________________________________________________
// Mark the active item

const urlToActiveItemIcon = '/site/static/output/images/icon-active.svg';
function setActiveItem($item) {
    deactivateCurrentlyActiveItem();

    if (getLevelOfItem($item) > 1) {
        const $activeContainer =
            $('<div></div>')
                .addClass('top-header__navigation-active-item')
                .prependTo($item);

        $activeContainer.prepend(
            $('<img></img>')
                .attr('src', urlToActiveItemIcon)
        );
    }
}

function deactivateCurrentlyActiveItem() {
    $('.top-header__navigation-active-item').remove();
}


// _____________________________________________________________________________
// Expand and collapse navigation itself (for mobile devices)

function toggleNavigationMenu() {
    if (isMenuExpanded) {
        collapseNavigationMenu();
    }
    else {
        expandNavigationMenu();
    }
}

function expandNavigationMenu() {
    _expandOrCollapseNavigationMenu(true);
}

function collapseNavigationMenu() {
    _expandOrCollapseNavigationMenu(false);
}

let leftPositionOfHamburgerAndNavigationContainerBeforeExpanding;
function _expandOrCollapseNavigationMenu(expandMenu) {
    menuAnimationIsRunning = true;
    const expandCollapseDuration = 500;
    const easingModeContainer = 'easeOutQuad';
    const easingModeIcons = 'swing';
    const collapseMenu = !expandMenu;

    const $navigationContainer = $('.top-header__navigation');
    if (expandMenu) {
        // When expanding, we need to show the container with the navigation items (which is hidden at first):
        $navigationContainer.css('display', 'block');
    }

    // _____________________________________________________________________________
    // Animation 1
    // We slide the menu to the right
    // Prepare elements
    const $hamburgerIconAndNavigationContainer = $('.top-header__hamburger-or-close-icon-and-navigation');
    let leftValueToApply;
    if (expandMenu) {
        leftPositionOfHamburgerAndNavigationContainerBeforeExpanding = $hamburgerIconAndNavigationContainer.position().left;
        leftValueToApply = 0;
    }
    else {
        leftValueToApply = leftPositionOfHamburgerAndNavigationContainerBeforeExpanding;
    }
    // Animate
    Velocity($hamburgerIconAndNavigationContainer, {
        left: leftValueToApply,
    }, {
            duration: expandCollapseDuration,
            easing: easingModeContainer,
        });


    // _____________________________________________________________________________
    // Animation 2
    // Hide the hamburger icon
    // Prepare elements
    const $hamburgerIconImage = $('.top-header__hamburger-icon-image');
    if (collapseMenu) {
        $hamburgerIconImage.css({
            display: 'block',
            opacity: 0,
        });
    }
    // Animate
    Velocity($hamburgerIconImage, {
        opacity: expandMenu ? 0 : 1,
    }, {
            duration: expandCollapseDuration,
            easing: easingModeIcons,
            complete: () => {
                if (expandMenu) {
                    $hamburgerIconImage.css('display', 'none');
                }
            },
        });

    // _____________________________________________________________________________
    // Animation 3
    // Show the close icon
    // Prepare elements
    const $closeIconImage = $('.top-header__close-icon-image');
    if (expandMenu) {
        $closeIconImage.css({
            display: 'block',
            opacity: 0,
        });
    }
    // Animate
    Velocity($closeIconImage, {
        opacity: expandMenu ? 1 : 0,
    }, {
            duration: expandCollapseDuration,
            easing: easingModeIcons,
            complete: () => {
                if (collapseMenu) {
                    $closeIconImage.css('display', 'none');
                }
                onLongestAnimationHasEnded();
            },
        });


    // When all animations have ended:
    function onLongestAnimationHasEnded() {
        if (collapseMenu) {
            $navigationContainer.css('display', 'none');
        }
        isMenuExpanded = expandMenu;
        menuAnimationIsRunning = false;
    }
}


// _____________________________________________________________________________
// Expand and collapse navigation items

function toggleContainer($container) {
    // We only toggle the level 2 container:
    if (getLevelOfContainer($container) === 2) {
        if ($container.is(':visible')) {
            // in this case we also collapse the descendants
            collapseContainers($container);
        }
        else {
            expandContainer($container);
        }
    }
}

const slidingDuration = 500;
const slidingEasingMode = 'easeOutQuad';
function expandContainer($container) {
    Velocity($container, 'slideDown', {
        duration: slidingDuration,
        easing: slidingEasingMode,
    });
}

function collapseContainerAndAllDescendants($container) {
    let $containerAndAllDescendants = $container.add(getAllDescendantContainersFromParentContainer($container));
    collapseContainers($containerAndAllDescendants);
}

function collapseContainers($containersToCollapse) {
    Velocity($containersToCollapse, 'slideUp', {
        duration: slidingDuration,
        easing: slidingEasingMode,
    });
}


// _____________________________________________________________________________
// Selector helpers

function getLevelOfContainer($container) {
    if ($container.hasClass('top-header__navigation-level-1-container')) {
        return 1;
    }
    else if ($container.hasClass('top-header__navigation-level-2-container')) {
        return 2;
    }
    else if ($container.hasClass('top-header__navigation-level-3-container')) {
        return 3;
    }
}

function getLevelOfItem($item) {
    if ($item.hasClass('top-header__navigation-level-1-item')) {
        return 1;
    }
    else if ($item.hasClass('top-header__navigation-level-2-item')) {
        return 2;
    }
    else if ($item.hasClass('top-header__navigation-level-3-item')) {
        return 3;
    }
}

function getNextLevelContainerFromItemLink($itemLink) {
    let $parentItem = getParentItemFromItemLink($itemLink)
    let $nextLevelContainer = $parentItem.find('ul').first();
    return $nextLevelContainer;
}

function getParentItemFromItemLink($itemLink) {
    let $parentItem = $itemLink.closest('li');
    return $parentItem;
}

function getParentContainerFromElement($element) {
    let $parentContainer = $element.closest('ul');
    return $parentContainer;
}

function getAllDescendantContainersFromParentContainer($parentContainer) {
    let $descendantContainers = $parentContainer.find('ul');
    return $descendantContainers;
}
